import type { InjectionKey } from 'vue'
import { breakpoints } from '@/composables/useBreakpoints'

export const useMypageMenu = () => {
  const isPc = breakpoints.greaterOrEqual('pc')

  const myMenu = ref<HTMLDivElement | null>(null)
  const myMenuHeight = ref(0)
  /** スクロール制御用 */
  const bodyContent = ref<HTMLBodyElement | null>(null)
  /** Side Menuの表示管理 */
  const isOpenSideMenu = ref(false)
  const isHideSideMenu = ref(true)
  /** MyPage Menuの表示管理 */
  const isOpenMyMenu = ref(false)
  const isHideMyMenu = ref(true)
  const TRANSITION_TIME = 200 as const

  /** マウスがヘッダーから離れたとき */
  const onMouseleaveMyMenu = () => {
    if (isPc.value && isOpenMyMenu.value) {
      // hideMyMenu()
    }
  }

  /** マイページメニューボタンをクリックしたとき */
  const onClickMypage = () => {
    if (!isOpenMyMenu.value) {
      openMyMenu()
    } else {
      hideMyMenu()
    }
  }

  /** マイページメニューを開く */
  const openMyMenu = () => {
    hideSideMenu()
    isHideMyMenu.value = false
    setTimeout(() => {
      isOpenMyMenu.value = true
    }, 0)

    if (!isPc.value) {
      disableScroll()
    }
  }

  /** マイページメニューを閉じる */
  const hideMyMenu = () => {
    isOpenMyMenu.value = false
    setTimeout(() => {
      isHideMyMenu.value = true
    }, TRANSITION_TIME)

    enableScroll()
  }

  /** 画面のスクロールを無効化する */
  const disableScroll = () => {
    if (bodyContent.value) {
      bodyContent.value.style.overflow = 'hidden'
    }
  }
  /** 画面のスクロールを有効化する */
  const enableScroll = () => {
    if (bodyContent.value) {
      bodyContent.value.style.overflow = ''
    }
  }

  /** サイドメニューを開く */
  const openSideMenu = () => {
    hideMyMenu()

    isHideSideMenu.value = false
    setTimeout(() => {
      isOpenSideMenu.value = true
    }, 0)

    disableScroll()
  }

  /** サイドメニューを閉じる */
  const hideSideMenu = () => {
    isOpenSideMenu.value = false
    setTimeout(() => {
      isHideSideMenu.value = true
    }, TRANSITION_TIME)

    enableScroll()
  }

  /** サイドメニューを開閉する */
  const toggleSideMenu = () => {
    if (isOpenSideMenu.value) {
      hideSideMenu()
    } else {
      openSideMenu()
    }
  }

  /** メニューを全て閉じる */
  const hideMenu = () => {
    hideMyMenu()
    isOpenSideMenu.value = false
  }

  return {
    myMenu,
    myMenuHeight,
    bodyContent,
    isOpenSideMenu,
    isHideSideMenu,
    isOpenMyMenu,
    isHideMyMenu,
    TRANSITION_TIME,
    onMouseleaveMyMenu,
    onClickMypage,
    openMyMenu,
    hideMyMenu,
    disableScroll,
    enableScroll,
    openSideMenu,
    hideSideMenu,
    toggleSideMenu,
    hideMenu,
  }
}

export type MypageMenuComposable = ReturnType<typeof useMypageMenu>

export const mypageMenuInjectionKey: InjectionKey<MypageMenuComposable> =
  Symbol('mypage-menu')
