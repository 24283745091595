<i18n lang="yaml">
ja:
  home: HOME
  signup: 新規登録
  talkroom: |-
    トーク
    ルーム
  play: |-
    イベント/
    ワールド
  cloudstore: ストア
  mypage: マイページ
  login: ログイン
en:
  home: HOME
  signup: SIGN UP
  talkroom: TALK ROOM
  play: |-
    EVENT/
    WORLD
  cloudstore: STORE
  mypage: MYPAGE
  login: LOGIN
</i18n>

<template>
  <nav class="ho-side-menu">
    <ul class="menu-list">
      <!-- HOME, 新規登録 -->
      <li
        class="item -home"
        :class="{ '-signup': !isGottenMe, '-is-current': isTop }"
      >
        <template v-if="isGottenMe">
          <HaLink class="link" to="/">
            <p class="icon-wrapper">
              <IconHome class="icon" />
            </p>
            <p class="name">{{ i18n.t('home') }}</p>
          </HaLink>
        </template>
        <template v-else>
          <HoMyVketButton class="signup" @click="onClickLogin">
            <IconMyvket class="icon" />
            <p class="name">{{ i18n.t('signup') }}</p>
          </HoMyVketButton>
        </template>
      </li>
      <li
        v-for="item in menus"
        :key="item.name"
        class="item"
        :class="[`-${item.id}`, { ['-is-current']: currentId === item.id }]"
      >
        <HaLink
          class="link"
          :to="item.link"
          :blank="item.blank"
          :redirect="item.redirect"
        >
          <p class="icon-wrapper">
            <component :is="item.icon" class="icon" />
          </p>
          <p class="name">{{ item.name }}</p>
        </HaLink>
      </li>
      <li
        class="item"
        :class="{
          '-mypage': isGottenMe,
          '-login': !isGottenMe,
          '-is-current': isMypage,
        }"
      >
        <template v-if="isGottenMe">
          <HaLink class="link" to="/mypage" :blank="false">
            <HmUserIcon :src="icon" class="icon -mypage" />
            <p class="name">{{ i18n.t('mypage') }}</p>
          </HaLink>
        </template>
        <template v-else>
          <div class="link" @click="onClickLogin">
            <p class="icon-wrapper -login">
              <IconLogin class="icon -login" />
            </p>
            <p class="name">{{ i18n.t('login') }}</p>
          </div>
        </template>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
// assets
import IconHome from '@/assets/icons/icon_home.svg'
import IconMyvket from '@/assets/icons/icon-myvket.svg'
import IconFriend from '@/assets/icons/icon_friend.svg'
import IconPlay from '@/assets/icons/icon_play.svg'
import IconShop from '@/assets/icons/icon_shop.svg'
import IconLogin from '@/assets/icons/icon_login.svg'

type Emits = {
  (emit: 'close'): void
}

const emits = defineEmits<Emits>()

const i18n = useI18n()
const route = useRoute()
const toast = useToast()

const { addToast } = toast
const { isGottenMe, login } = useAuth()
const { state } = useMyProfile()

const isTop = computed(() => {
  return /^(\/|\/en\/)$/.test(route.path)
})
const isMypage = computed(() => {
  return /^(\/|\/en\/)mypage(\/.*)?$/.test(route.path)
})

const icon = computed(() => {
  return state.value?.profile?.icon?.url ?? ''
})

const menus = computed(() => [
  {
    id: 'talkroom',
    name: i18n.t('talkroom'),
    icon: IconFriend,
    link: LINK.TALKROOM,
    blank: false,
    redirect: false,
  },
  {
    id: 'play',
    name: i18n.t('play'),
    icon: IconPlay,
    link: LINK.EVENT,
    blank: false,
    redirect: false,
  },
  {
    id: 'cloudstore',
    name: i18n.t('cloudstore'),
    icon: IconShop,
    link: LINK.CLOUDSTORE,
    blank: false,
    redirect: false,
  },
])
// 選択中menu
const currentId = ref<string | void>()
const setCurrentId = () => {
  const currentMenu = menus.value.find((menu) => {
    const regex = new RegExp(`^(\\/en)?${menu.link}(\\/.*)?$`)
    return route.hash ? menu.link.includes(route.hash) : regex.test(route.path)
  })
  currentId.value = currentMenu?.id
}

watch(route, setCurrentId)

onMounted(() => {
  setCurrentId()
})

const onClickLogin = async () => {
  const result = await login()
  if (result) {
    addToast(i18n.t(result), 'error')
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;
@use 'sass:color';

.ho-side-menu {
  background: v.$gray-2;
  box-shadow: v.$box-shadow-2;
  height: calc(100vh - v.$header-height-pc);
  overflow: hidden;
  position: fixed;
  top: v.$header-height-pc;
  width: v.$side-menu-width;
  z-index: v.$zindex-side-menu;

  @include m.sp() {
    height: calc(100lvh - v.$header-height-sp);
    left: 0;
    top: v.$header-height-sp;
  }
}

.menu-list {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: v.space(2);
  width: v.$side-menu-width;

  .item {
    border-radius: 10px;
    cursor: pointer;
    filter: brightness(100%);
    padding-bottom: 0;
    text-align: center;
    transition: filter ease 0.1s;

    + .item {
      margin-top: v.space(4);
    }

    &.-home.-signup,
    &.-mypage {
      width: 100%;
    }

    .icon {
      height: 30px;
      width: 30px;

      &.-mypage {
        height: 40px;
        width: 40px;
      }

      :deep(path) {
        fill: v.$gray-5;
      }
    }

    .name {
      color: v.$gray-5;
      font-size: 12px;
      white-space: pre-wrap;
    }

    .signup {
      border-radius: 10px;
      padding: v.space(2) 0;
      width: 100%;

      &:hover {
        background-color: v.$primary-color;
      }

      .icon {
        :deep(path) {
          fill: v.$white;
        }
      }

      > .name {
        color: v.$white;
      }
    }

    .link {
      align-items: center;
      display: flex;
      flex-direction: column;
      @include m.sp() {
        border-radius: 0;
        padding: 0;
      }
    }

    .icon-wrapper {
      align-items: center;
      aspect-ratio: 1;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      padding: v.space(1);

      transition: background-color ease 0.2s;
      width: 44px;
    }

    &.-is-current {
      &.-home:not(.-signup) {
        .icon-wrapper {
          > .icon :deep(path) {
            fill: v.$primary-color;
          }
        }

        .name {
          color: v.$primary-color;
        }
      }

      &.-talkroom {
        .icon-wrapper {
          > .icon :deep(path) {
            fill: v.$talkroom-color;
          }
        }

        .name {
          color: v.$talkroom-color;
        }
      }

      &.-play {
        .icon-wrapper {
          > .icon :deep(path) {
            fill: v.$play-color;
          }
        }

        .name {
          color: v.$play-color;
        }
      }

      &.-cloudstore {
        .icon-wrapper {
          > .icon :deep(path) {
            fill: v.$store-color;
          }
        }

        .name {
          color: v.$store-color;
        }
      }

      &.-mypage {
        .icon {
          border: 2px solid v.$primary-color;
          box-shadow: 0 0 5px 0 v.$primary-color;
        }

        .name {
          color: v.$primary-color;
        }
      }
    }

    &:hover {
      &.-home:not(.-signup) {
        .icon-wrapper {
          background-color: v.$primary-color;

          > .icon :deep(path) {
            fill: v.$white;
          }
        }
      }

      &.-talkroom {
        .icon-wrapper {
          background-color: v.$talkroom-color;

          > .icon :deep(path) {
            fill: v.$white;
          }
        }
      }

      &.-play {
        .icon-wrapper {
          background-color: v.$play-color;

          > .icon :deep(path) {
            fill: v.$white;
          }
        }
      }

      &.-cloudstore {
        .icon-wrapper {
          background-color: v.$store-color;

          > .icon :deep(path) {
            fill: v.$white;
          }
        }
      }

      &.-login {
        .icon-wrapper {
          background-color: v.$primary-color;

          > .icon :deep(path) {
            fill: v.$white;
          }
        }
      }
    }

    .image {
      border-radius: 50%;
      height: 50px;
      width: 50px;
    }
  }
}
</style>
